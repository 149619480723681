<template>
    <v-container fluid>
        <v-alert dense outlined type="error" v-if="Object.keys(validation_errors).length > 0">
            <ul>
                <li v-for="(errors, field) in validation_errors" :key="field">
                    {{ field }}
                    <ul>
                        <li v-for="(error, sl) in errors" :key="sl">
                            {{ error }}
                        </li>
                    </ul>
                </li>
            </ul>
        </v-alert>

        <v-row>
            <v-col cols="12" class="py-1">
                <v-card :elevation="1">
                    <v-toolbar color="white" :elevation="1" height="30px">
                        <v-toolbar-title class="subtitle-2">Shift Entry</v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="py-0">
                        <v-form @submit.prevent="saveShift" ref="shiftForm" class="custom-form">
                            <v-row>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col offset="2" cols="1" class="py-1 caption text-center">Name</v-col>
                                        <v-col cols="3" class="py-0">
                                            <v-text-field dense outlined hide-details :rules="[(v) => !!v]"
                                                v-model.trim="shift.name"
                                                :error-messages="validation_errors.name"></v-text-field>
                                        </v-col>

                                        <v-col cols="1" class="py-1 caption text-center">Start at</v-col>
                                        <v-col cols="3" class="py-0">
                                            <v-text-field dense outlined hide-details type="time" :rules="[(v) => !!v]"
                                                v-model="shift.start_at"
                                                :error-messages="validation_errors.start_at"></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col offset="2" cols="1" class="py-1 caption text-center">End at</v-col>
                                        <v-col cols="3" class="py-1">
                                            <v-text-field dense outlined hide-details type="time" :rules="[(v) => !!v]"
                                                v-model="shift.end_at"
                                                :error-messages="validation_errors.end_at"></v-text-field>
                                        </v-col>

                                        <v-col cols="1" class="py-1 caption text-center">Late Time</v-col>
                                        <v-col cols="3" class="py-1">
                                            <v-text-field dense outlined hide-details type="time" :rules="[(v) => !!v]"
                                                v-model="shift.late_time"
                                                :error-messages="validation_errors.late_time"></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col offset="2" cols="1" class="py-1 caption text-center">Lunch Start</v-col>
                                        <v-col cols="3" class="py-0">
                                            <v-text-field dense outlined hide-details type="time" :rules="[(v) => !!v]"
                                                v-model="shift.lunch_start_at"
                                                :error-messages="validation_errors.lunch_start_at"></v-text-field>
                                        </v-col>

                                        <v-col cols="1" class="py-1 caption text-center">Lunch End</v-col>
                                        <v-col cols="3" class="py-0">
                                            <v-text-field dense outlined hide-details type="time" :rules="[(v) => !!v]"
                                                v-model="shift.lunch_end_at"
                                                :error-messages="validation_errors.lunch_end_at"></v-text-field>
                                        </v-col>
                                        <v-col cols="2" class="py-0">
                                            <v-checkbox v-model="shift.is_lunch_active" class="my-0" dense
                                                label="Is Lunch Active"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col offset="2" cols="1" class="py-1 caption text-center">Ot Start</v-col>
                                        <v-col cols="3" class="py-1">
                                            <v-text-field dense outlined hide-details type="time" :rules="[(v) => !!v]"
                                                v-model="shift.ot_start_time"
                                                :error-messages="validation_errors.ot_start_time"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col offset="2" cols="6">
                                            <v-row>
                                                <v-col offset="2" cols="3" class="py-0">
                                                    <v-checkbox v-model="shift.absent_on" class="my-0" dense
                                                        label="On Absent" hide-details></v-checkbox>
                                                </v-col>
                                                <v-col cols="6" class="py-0">
                                                    <v-text-field dense outlined hide-details type="time"
                                                        v-if="shift.absent_on" v-model="shift.absent_time"
                                                        :error-messages="validation_errors.absent_time"></v-text-field>
                                                </v-col>
                                                <v-col offset="2">
                                                    <span style="color: red;">(Absent, if enter after this time)</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col cols="2" class="py-0">
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-btn type="submit" height="26px" dark color="light-blue darken-2"
                                                        :elevation="1" class="mr-1" :loading="loading">Save</v-btn>
                                                    <v-btn height="26px" dark color="deep-orange" :elevation="1"
                                                        @click="resetForm">Cancel</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" class="pb-0">
                <v-data-table class="custom-data-table elevation-1" dense :headers="shiftHeaders"
                    :items="$store.getters['shift/shifts']" :search="searchShift">
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Shift List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field outlined dense hide-details placeholder="Search Shift"
                                    append-icon="mdi-magnify" style="width: 300px" v-model="searchShift">
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>

                    <template v-slot:[`item.start_at`]="{ item }">
                        <span>{{ item.start_at | timeFormat }}</span>
                    </template>

                    <template v-slot:[`item.end_at`]="{ item }">
                        <span>{{ item.end_at | timeFormat }}</span>
                    </template>

                    <template v-slot:[`item.late_time`]="{ item }">
                        <span>{{ item.late_time | timeFormat }}</span>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom v-if="$store.state['user'].role != 'User'">
                            <template v-slot:activator="{ on }">
                                <v-icon small @click="editShift(item)" color="primary"
                                    v-on="on">mdi-circle-edit-outline</v-icon>
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="$store.state['user'].role == 'Admin'">
                            <template v-slot:activator="{ on }">
                                <v-icon small @click="deleteShift(item)" color="error"
                                    v-on="on">mdi-delete-circle-outline</v-icon>
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import utility from '../../mixins/utility.mixin';
export default {
    name: "Shifts",
    mixins: [utility],
    data() {
        return {
            shiftHeaders: [
                { text: "Name", value: "name" },
                { text: "Start at", value: "start_at" },
                { text: "End at", value: "end_at" },
                { text: "Late Time", value: "late_time" },
                { text: "Action", value: "action" },
            ],
            searchShift: "",

            shift: {
                name: "",
                start_at: null,
                end_at: null,
                lunch_start_at: null,
                lunch_end_at: null,
                absent_time: null,
                ot_start_time: null,
                is_lunch_active: true,
                absent_on: false,
                late_time: null,
            },
            editId: null,
            validation_errors: {},
            loading: false,
        };
    },
    computed: {
        absent_on() {
            return this.shift.absent_on;
        }
    },
    watch: {
        absent_on(on) {
            if (!on) {
                this.shift.absent_time = null;
            }
        }
    },
    created() {
        this.$store.dispatch("shift/getShifts");
    },
    methods: {
        async saveShift() {
            if (!this.$refs.shiftForm.validate()) return;

            if (this.shift.absent_on && this.shift.absent_time == null) {
                alert('Absent time required');
                return;
            }
            this.loading = true;

            let data = {
                url: "/add-shift",
                shift: this.shift,
            };

            if (this.editId) {
                data.url = "/update-shift";
                data.shift.id = this.editId;
            }

            let resObj = await this.$store.dispatch(
                "shift/saveShift",
                data
            );

            if (resObj.hasOwnProperty("validation_errors")) {
                this.validation_errors = resObj.validation_errors;
            }
            this.loading = false;

            if (resObj.isSuccess) {
                this.resetForm();
            }

        },
        editShift(shift) {
            Object.keys(this.shift).forEach(
                (k) => (this.shift[k] = shift[k])
            );

            this.editId = shift.id;
        },
        deleteShift(shift) {
            if (!confirm("Are you sure?")) return;

            this.$store.dispatch("shift/deleteShift", shift.id);
        },
        resetForm() {
            Object.keys(this.shift).forEach(
                (k) => (this.shift[k] = "")
            );

            this.shift.is_lunch_active = true;
            this.shift.absent_on = false;

            this.editId = null;
            this.$refs.shiftForm.resetValidation();
            this.validation_errors = {};
        },
    },
};
</script>

<style>
.custom-form .v-input__slot .v-label {
    top: 0 !important;
}
</style>
